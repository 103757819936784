<template>
  <div :style="bg" class="container">
    <div class="loginbox">
      <div class="headbar">
        <div>十万八商家登陆</div>
        <el-button type="primary" @click="reg" size="mini">新用户注册</el-button>
      </div>
      <div class="logincontent" v-loading="loading">
        <div v-if="show === 0">
          <img :src="wx_code" width="240px" alt v-if="codeValid" />
          <div v-else class="qrempty">二维码已过期，请刷新重试</div>
        </div>
        <!-- 手机号登陆界面 -->
        <div v-if="show === 1" class="loginbox-g">
          <el-form :model="bizLogin" :rules="rules" ref="bizLogin" hide-required-asterisk>
            <el-form-item prop="regphone">
              <el-input
                v-model.trim="bizLogin.regphone"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                placeholder="请输入管理员注册手机号"
                maxlength="11"
              >
                <template slot="append">
                  <div class="slotstyle">{{ bizLogin.regphone.length }} /11</div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="validCode">
              <el-input
                v-model.trim="bizLogin.validCode"
                oninput="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                placeholder="请输入验证码"
                maxlength="6"
              >
                <template slot="append">
                  <div class="slotstyle">
                    <el-button @click="getCode" :disabled="btnBool">{{ this.yzmtext }}</el-button>
                  </div>
                </template>
              </el-input>
            </el-form-item>

            <el-button type="info" @click="submit('bizLogin')" style="width: 100%">提交</el-button>
          </el-form>
        </div>
        <!-- 登陆选择 -->
        <div v-if="show == 2">
          <div v-for="(item, index) in sightCompanies" :key="index+'a'">
            <el-button type="primary" @click="loginSelect(item,'sightCompany')" class="selectwrap">
              {{ item.noteNameBiz }}
              <span v-if="item.membType==1" style="font-size:13px">(成员)</span>
            </el-button>
          </div>
        </div>
      </div>
      <div v-if="show == 2" class="footbar">
        <div class="gray">请选择账户登陆</div>
      </div>
      <div class="footbar" v-else>
        <div @click="changeLoginType(0)" :class="show == 0 ? 'orange' : 'gray leftline'">微信扫码登陆</div>
        <div @click="changeLoginType(1)" :class="show == 1 ? 'orange' : 'gray rightline'">手机号登陆</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  sendPhone,
  phoneLogin,
  bizLogin,
  getQrcode,
  queryTicket,
  checkLogin
} from "@/api/api";
let timer;
export default {
  data() {
    return {
      btnBool: false,
      yzmtext: "发送验证码",
      pathType: "",
      codeValid: true,
      show: 0, //微信登陆，手机登陆，登陆账号选择的切换
      bizLogin: {
        regphone: "",
        validCode: ""
      },
      rules: {
        regphone: [
          { required: true, message: "请输入正确的电话号码", trigger: "blur" }
        ],
        validCode: [
          { required: true, message: "请输入正确的验证码", trigger: "blur" }
        ]
      },
      //res.data
      bizs: [],
      wx_code: "",
      loading: true,
      bg: {
        backgroundImage: "url(" + require("@/assets/logoback.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      }
    };
  },
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      const that = this;
      let session = localStorage.getItem("Authorization");
      if (session) {
        checkLogin({
          session: session
        }).then(res => {
          if (res.message == "800") {
            localStorage.setItem("Authorization", res.data.token);
            that.loginSelect(res.data.biz);
          } else {
            localStorage.setItem("Authorization", "");
            that.getQrcodeFn();
          }
        });
      } else {
        that.getQrcodeFn();
      }
    },

    changeLoginType(type) {
      this.show = type;
      if (type == 0) {
        this.timingFn();
      } else {
        clearTimeout(timer);
      }
    },

    getQrcodeFn() {
      const that = this;
      getQrcode().then(res => {
        if (res.result) {
          that.wx_code = res.data.url;
          that.ticket = res.data.ticket;
          that.timingFn();
        }
        that.loading = false;
      });
    },

    ValidationPhoneNumber() {
      let pattern = /^1[3456789]\d{9}$/;
      let test = pattern.test(this.bizLogin.regphone);
      if (test) {
        return true;
      } else {
        this.$alert("请输入正确的手机号码", {
          confirmButtonText: "确定",
          type: "error",
          callback: () => {}
        });
      }
    },

    getCode() {
      const that = this;
      if (that.ValidationPhoneNumber()) {
        sendPhone({
          regphone: that.bizLogin.regphone,
          type: 5
        }).then(res => {
          if (res.result) {
            this.$alert("验证码已发送", {
              confirmButtonText: "确定",
              type: "success",
              callback: () => {}
            });
            that.btnBool = true;
            let i = 60;
            let timer = setInterval(() => {
              that.yzmtext = i + "s 后重发";
              i--;
              if (i < 0) {
                that.btnBool = false;
                that.yzmtext = "重新发送";
                clearInterval(timer);
              }
            }, 1000);
          }
        });
      }
    },
    reg() {
      this.$router.push({
        path: "/regwechat",
        query: { type: JSON.stringify("reg") }
      });
    },
    submit() {
      const that = this;
      if (!that.ValidationPhoneNumber()) {
        return;
      }
      phoneLogin({
        regphone: that.bizLogin.regphone,
        vCode: that.bizLogin.validCode
      }).then(res => {
        if (res.result) {
          if (res.message == "账户缺失") {
            if (res.data == 1) {
              this.$alert(
                "您还未绑定十万八账户，请先在手机端绑定账户",
                "账户信息缺失",
                {
                  type: "warning",
                  callback: () => {
                    that.$router.push({
                      path: "/regwechat",
                      query: { type: JSON.stringify("wechat") }
                    });
                  }
                }
              );
            } else {
              this.$alert(
                "您还未关注十万八公众号。请在手机端完成上述操作",
                "账户信息缺失",
                {
                  type: "warning",
                  callback: () => {
                    that.$router.push({
                      path: "/regwechat",
                      query: { type: JSON.stringify("wechat") }
                    });
                  }
                }
              );
            }
          } else {
            that.sightCompanies = res.data.sightCompanies;
            let total = res.data.sightCompanies.length;
            if (total == 0) {
              this.$confirm(
                "您还未注册，是否现在去注册一个账户？",
                "登陆提示",
                {
                  confirmButtonText: "注册",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.$router.push({
                    path: "/regwechat",
                    query: { type: JSON.stringify("reg") }
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取"
                  });
                });
            } else if (total == 1) {
              that.loginSelect(that.sightCompanies[0]);
            } else {
              that.show = 2;
            }
          }
        }
      });
    },
    loginSelect(item) {
      const that = this;
      bizLogin({
        id: item.id,
        idUser: item.idUser
      }).then(res => {
        if (res.result) {
          localStorage.setItem("Authorization", res.data.Authorization);
          item.msgCount = res.data.msgCount;
          that.login(item);
        } else {
          this.$message({
            type: "warning",
            message: "你还未注册商家!"
          });
        }
      });
    },
    login(item) {
      switch (item.state) {
        //0，正常，1，禁用, 2,未审核，3，待审核，4，审核失败
        case 0:
          this.$store.dispatch("setBiz", item);
          this.$router.push({ path: "/sightcompany" });
          break;
        case 1:
          this.$alert("该账号已被禁用，请联系十万八客服！", "提示", {
            type: "warning",
            callback: () => {
              localStorage.setItem("Authorization", "");
            }
          });
          break;
        case 2:
          this.$confirm(
            "您还未完成注册流程，请在手机端继续完成注册！",
            "登陆提示",
            {
              confirmButtonText: "继续注册",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              this.$router.push({
                path: "/regwechat",
                query: { type: JSON.stringify("reg") }
              });
            })
            .catch(() => {
              localStorage.setItem("Authorization", "");
              this.$message({
                type: "info",
                message: "已取消登陆"
              });
            });

          break;
        case 3:
          this.$confirm(
            "您的注册申请仍在审核中，如需修改注册信息，请在手机端进行！",
            "登陆提示",
            {
              confirmButtonText: "修改信息",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              this.$router.push({
                path: "/regwechat",
                query: { type: JSON.stringify("reg") }
              });
            })
            .catch(() => {
              localStorage.setItem("Authorization", "");
              this.$message({
                type: "info",
                message: "已取消登陆"
              });
            });

          break;
        case 4:
          this.$confirm(
            "您的注册申请未通过，请请在手机端修改信息并重新提交！",
            "登陆提示",
            {
              confirmButtonText: "修改信息",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              this.$router.push({
                path: "/regwechat",
                query: { type: JSON.stringify("reg") }
              });
            })
            .catch(() => {
              localStorage.setItem("Authorization", "");
              this.$message({
                type: "info",
                message: "已取消登陆"
              });
            });
      }
    },
    timingFn() {
      const that = this;
      timer = setInterval(function() {
        that.queryTicket(that.ticket);
      }, 2000);
    },
    queryTicket(ticket) {
      const that = this;
      queryTicket({
        ticket: ticket
      }).then(res => {
        if (res.result) {
          if (res.data.result) {
            that.sightCompanies = res.data.sightCompanies;
            let total = res.data.sightCompanies.length;
            if (res.data.isUser == -1) {
              that.codeValid = false;
            } else if (res.data.isUser == 0) {
              this.$alert(
                "您还未绑定十万八账户，请先在手机端绑定账户",
                "账户信息缺失",
                {
                  type: "warning",
                  callback: () => {
                    that.$router.push({
                      path: "/regwechat",
                      query: { type: JSON.stringify("wechat") }
                    });
                  }
                }
              );
            } else if (total == 0) {
              this.$confirm(
                "您还未注册商家账户，是否现在去注册？",
                "登陆提示",
                {
                  confirmButtonText: "注册",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  that.$router.push({
                    path: "/regwechat",
                    query: { type: JSON.stringify("reg") }
                  });
                })
                .catch(() => {
                  that.$message({
                    type: "info",
                    message: "已取消登陆"
                  });
                  this.getQrcodeFn();
                });
            } else if (total == 1) {
              that.loginSelect(that.sightCompanies[0]);
            } else {
              that.show = 2;
            }
            clearTimeout(timer);
          }
        } else {
          clearTimeout(timer);
        }
      });
    }
  },
  beforeDestroy() {
    clearTimeout(timer);
  }
};
</script>

<style scoped>
.selectwrap {
  min-width: 300px;
  margin: 5px auto;
}
.container {
  height: calc(100vh - 80px);
  padding-top: 80px;
}
.loginbox {
  width: 60%;
  margin: 0px auto 0 auto;
  box-shadow: 0 0 10px #555;
  min-height: 500px;
  background: #fff;
  position: relative;
}
.headbar {
  font-size: 24px;
  padding: 20px 40px;
  background: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.logincontent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 340px;
  padding-bottom: 50px;
}
.slotstyle {
  width: 70px;
  text-align: center;
}
.footbar {
  background: #555;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.orange {
  padding: 15px 0;
  background: darkorange;
  width: 150px;
  cursor: pointer;
}
.leftline {
  border-left: 1px solid #666;
}
.gray {
  padding: 15px 0;
  width: 150px;
  cursor: pointer;
}
.qrempty {
  width: 220px;
  line-height: 220px;
  border: 1px solid #eee;
  background: #999;
  text-align: center;
  color: #fff;
}
</style>
